.PageHeading {
    font-family: Montserrat;
    font-size: 33px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.PageBlurb {
    font-family: Arial, Helvetica, sans-serif;
    color: #333333;
    font-size: 16px;
    max-width: 700px;
}