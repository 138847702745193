.LeftPanel {
    background: #164216;
}

.LeftPanel hr {
    border: solid 1px #446644;
}

.LeftPanelItem {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #888888;
    cursor: pointer;
}

.LeftPanelItem.selected {
    color: green
}

.LeftPanelItem:hover {
    background-color: #266226;
}